


























import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/common/Modal.vue'
import Timer from '@/components/common/Timer.vue'
import { bus } from '@/components/common/Chat.vue'

@Component({
  components: {
    Modal,
    Timer
  }
})
export default class ScreenThree extends Vue {
  private timerDurationInSeconds = 60 * 1 // 1min
  private isEnd = false
  private isTimerResetConfirmationModalOpen = false
  private unwatchTimerFinished: (() => void) | null = null

  private mounted (): void {
    bus.$emit('PAGE_LOADED')

    if ((window.location.host.includes('localhost')) || window.location.host.includes('dev.events.bella.health')) {
      this.timerDurationInSeconds = 30
    }

    if (this.$store.getters['timer/finished'] === null && this.$store.getters['timer/sourceLocation']?.name === undefined) {
      this.checkIsEnd()
      this.$store.dispatch('timer/start', { sourceLocation: { name: 'quidel 2' }, durationInSeconds: this.timerDurationInSeconds })
    } else if (this.$store.getters['timer/sourceLocation']?.name === this.$route?.name) {
      this.checkIsEnd()
      this.$store.dispatch('timer/disableTimerBar')
    } else {
      this.isEnd = true
    }
  }

  private checkIsEnd ():void {
    this.isEnd = this.$store.getters['timer/finished']
    this.unwatchTimerFinished = this
      .$store
      .watch(
        (state, getters) => getters['timer/finished'],
        (timerFinished) => {
          this.isEnd = timerFinished
        },
        { immediate: true }
      )
  }

  private resetTimer ():void {
    this.$store.dispatch('timer/restart')
    this.isTimerResetConfirmationModalOpen = false
  }

  private startSubmitTimeoutTimerAndContinue (): void {
    this
      .$store
      .dispatch('walkthrough/startRemainingTimeCountdown')
      .then(() => {
        this.$router.push({ name: 'quidel 3' })
      })
  }

  private goTo (): void {
    if (this.$store.getters['timer/finished'] && this.$store.getters['timer/sourceLocation']?.name === this.$route.name) {
      this.$store.dispatch('timer/clear')
        .then(() => {
          this.goToNext()
        })
    }
    this.goToNext()
  }

  private goToNext ():void {
    this.$router.push('/quidel/3')
  }

  private onlyDesktop (): boolean {
    return window.innerWidth > this.$data.mobileWidth
  }

  private destroyed (): void {
    if (this.unwatchTimerFinished) this.unwatchTimerFinished()
    this.$store.dispatch('timer/enableTimerBar')
  }
}
