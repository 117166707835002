











































import { Component, Vue } from 'vue-property-decorator'

const STROKE_DASHARRAY_LENGTH = 770// full circle
@Component({})
export default class Timer extends Vue {
  private timerStr = ''
  private visibleArcLength = 0
  private unwatchRemainingTime: (() => void) | null = null
  private unwatchPercentage: (() => void) | null = null

  private mounted (): void {
    if (this.$store.getters['timer/sourceLocation']?.name === undefined || this.$store.getters['timer/sourceLocation']?.name === this.$route.name) {
      this.timerStr = this.$store.getters['timer/timeRemainingInHumanReadableString']
      this.unwatchRemainingTime = this
        .$store
        .watch(
          (state, getters) => getters['timer/timeRemainingInHumanReadableString'],
          (timeRemainingInHumanReadableFormat) => {
            this.timerStr = timeRemainingInHumanReadableFormat
          },
          { immediate: true }
        )
      this.visibleArcLength = STROKE_DASHARRAY_LENGTH -
      (STROKE_DASHARRAY_LENGTH / 100) * this.$store.getters['timer/timeRemainingInPercentage']
      this.unwatchPercentage = this
        .$store
        .watch(
          (state, getters) => getters['timer/timeRemainingInPercentage'],
          (percentage) => {
            this.visibleArcLength =
            STROKE_DASHARRAY_LENGTH -
            (STROKE_DASHARRAY_LENGTH / 100) * percentage
          }
        )
    } else {
      this.timerStr = '00:00'
      this.visibleArcLength = 770
    }
  }

  private destroyed (): void {
    if (this.unwatchRemainingTime) this.unwatchRemainingTime()
    if (this.unwatchPercentage) this.unwatchPercentage()
  }
}
